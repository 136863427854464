import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/auth/Login.vue";
import store from "../store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "",
    component: () => import("../views/layout/LoggedInLayout.vue"),
    children: [
      // {
      //   title: 'Dashboard',
      //   path: '',
      //   name: 'dashboard',
      //   component: () => import('../views/dashboard/Dashboard.vue'),
      // },
      {
        title: "Dashboard",
        path: "",
        name: "Dashboard",
        component: () => import("../views/dashboard/Dashboard.vue"),
      },
      {
        title: "Sites",
        path: "sites",
        name: "Sites",
        component: () => import("../views/sites/Sites.vue"),
      },
      {
        title: "New Site",
        path: "sites/new",
        name: "NewSite",
        component: () => import("../views/sites/NewSite.vue"),
      },
      {
        title: "Installers",
        path: "sites/installers",
        name: "Installers",
        component: () => import("../views/sites/installers/Installers.vue"),
      },
      {
        title: "Brands",
        path: "sites/brands",
        name: "Brands",
        component: () => import("../views/sites/brands/Brands.vue"),
      },
      {
        title: "Groups",
        path: "sites/groups",
        name: "SiteGroups",
        component: () => import("../views/sites/groups/Groups.vue"),
      },
      {
        title: "New Site Group",
        path: "sites/groups/new",
        name: "NewSiteGroup",
        component: () => import("../views/sites/groups/NewGroup.vue"),
      },
      {
        title: "Site Group",
        path: "sites/groups/:id",
        props: true,
        name: "SiteGroup",
        component: () => import("../views/sites/groups/Group.vue"),
      },
      {
        title: "Site",
        path: "sites/:id",
        props: true,
        name: "Site",
        component: () => import("../views/sites/site/Site.vue"),
      },
      {
        title: "Camera",
        path: "sites/:siteId/cameras/:id",
        props: true,
        name: "Camera",
        component: () => import("../views/cameras/Camera.vue"),
      },
      {
        title: "CameraModuleSettings",
        path: "sites/:siteId/cameras/:id/module/settings",
        props: true,
        name: "CameraModuleSettings",
        component: () => import("../views/cameras/CameraModuleSettings.vue"),
      },
      {
        title: "Display",
        path: "sites/:siteId/displays/:id",
        props: true,
        name: "Display",
        component: () => import("../views/displays/Display.vue"),
      },
      {
        title: "Clients",
        path: "clients",
        props: true,
        name: "Clients",
        component: () => import("../views/clients/Clients.vue"),
      },
      {
        title: "New Client",
        path: "clients/new",
        name: "NewClient",
        component: () => import("../views/clients/New.vue"),
      },
      {
        title: "Client",
        path: "clients/:id",
        props: true,
        name: "Client",
        component: () => import("../views/clients/Client.vue"),
      },
      {
        title: "Client Whitelist",
        path: "clients/:id/whitelist",
        props: true,
        name: "ClientWhitelist",
        component: () => import("../views/clients/Whitelist.vue"),
      },
      {
        title: "Client Documents",
        path: "clients/:id/documents",
        props: true,
        name: "ClientDocuments",
        component: () => import("../views/clients/Documents.vue"),
      },
      {
        title: "Users",
        path: "users",
        props: true,
        name: "Users",
        component: () => import("../views/users/Users.vue"),
      },
      {
        title: "New User",
        path: "users/new",
        props: true,
        name: "NewUser",
        component: () => import("../views/users/New.vue"),
      },
      {
        title: "User",
        path: "users/:id",
        props: true,
        name: "User",
        component: () => import("../views/users/User.vue"),
      },
      {
        title: "Staging",
        path: "staging",
        props: true,
        name: "Staging",
        component: () => import("../views/staging/Staging.vue"),
      },
      {
        title: "Map",
        path: "map",
        props: true,
        name: "Map",
        component: () => import("../views/sites/Map.vue"),
      },
      {
        title: "Logs",
        path: "logs",
        props: true,
        name: "Logs",
        component: () => import("../views/logs/Logs.vue"),
      },
      {
        title: "Modules",
        path: "modules",
        props: true,
        name: "Modules",
        component: () => import("../views/modules/Modules.vue"),
      },
      {
        title: "New Module",
        path: "modules/new",
        props: true,
        name: "NewModule",
        component: () => import("../views/modules/NewModule.vue"),
      },
      {
        title: "Module",
        path: "modules/:id",
        props: true,
        name: "Module",
        component: () => import("../views/modules/Module.vue"),
      },
      {
        title: "Controller",
        path: "controllers/:id",
        props: true,
        name: "Controller",
        component: () => import("../views/controllers/Controller.vue"),
      },
      {
        title: "Module Monitor",
        path: "controllers/:id/modules/:moduleId/monitor",
        props: true,
        name: "ModuleMonitor",
        component: () => import("../views/controllers/modules/Module.vue"),
      },
      {
        title: "System",
        path: "system",
        props: true,
        name: "System",
        component: () => import("../views/system/System.vue"),
      },
      {
        title: "Purge Logs",
        path: "system/purge",
        props: true,
        name: "Purge Logs",
        component: () => import("../views/system/pages/Purge.vue"),
      },
      {
        title: "Purge Job",
        path: "system/purge/:id",
        props: true,
        name: "Purge Job",
        component: () => import("../views/system/pages/PurgeJob.vue"),
      },
      {
        title: "Event Feed",
        path: "system/events",
        props: true,
        name: "Event Feed",
        component: () => import("../views/system/pages/EventFeed.vue"),
      },
      {
        title: "Face Feed",
        path: "system/faces",
        props: true,
        name: "Face Feed",
        component: () => import("../views/system/pages/FaceFeed.vue"),
      },
      {
        title: "Global Whitelist",
        path: "system/whitelist",
        props: true,
        name: "Whitelist",
        component: () => import("../views/system/pages/Whitelist.vue"),
      },
      {
        title: "Search by Mac",
        path: "system/macsearch",
        props: true,
        name: "Mac Address Search",
        component: () => import("../views/system/pages/MacSearch.vue"),
      },
      {
        title: "System Notifications",
        path: "system/notifications",
        props: true,
        name: "System Notifications",
        component: () => import("../views/system/pages/Notifications.vue"),
      },
      {
        title: "Evidence Types",
        path: "system/evidencetypes",
        props: true,
        name: "Manage Evidence Types",
        component: () => import("../views/system/pages/ClaimEvidenceTypes.vue"),
      },
      {
        title: "Products",
        path: "system/products",
        props: true,
        name: "Manage Products",
        component: () => import("../views/system/pages/Products.vue"),
      },
      {
        title: "Police Markerts",
        path: "system/police",
        props: true,
        name: "Manage Police Markers",
        component: () => import("../views/system/pages/PoliceMarkers.vue"),
      },
      {
        title: "Accounting",
        path: "accounting",
        props: true,
        name: "Accounting",
        component: () => import("../views/accounting/Accounting.vue"),
      },
      {
        title: "Invoicing",
        path: "accounting/invoicing",
        props: true,
        name: "Invoicing",
        component: () => import("../views/accounting/invoicing/Invoicing.vue"),
      },
      {
        title: "Remittances",
        path: "accounting/remittances",
        props: true,
        name: "Remittances",
        component: () =>
          import("../views/accounting/remittances/Remittances.vue"),
      },
      {
        title: "Selfbilling Invoices",
        path: "accounting/selfbilling",
        props: true,
        name: "SelfbillingInvoices",
        component: () =>
          import("../views/accounting/selfbilling/Selfbilling.vue"),
      },
      {
        title: "Accounting Reports",
        path: "accounting/reports",
        props: true,
        name: "AccountingReports",
        component: () => import("../views/accounting/reports/Reports.vue"),
      },
      {
        title: "Accounting Reports - Paid on Site",
        path: "accounting/reports/paidonsite",
        props: true,
        name: "AccountingReportsPaidOnSite",
        component: () => import("../views/accounting/reports/PaidOnSite.vue"),
      },
      {
        title: "Accounting Imports",
        path: "accounting/imports",
        props: true,
        name: "AccountingImports",
        component: () => import("../views/accounting/import/Imports.vue"),
      },
      {
        title: "Accounting Imports - DCBL",
        path: "accounting/imports/dcbl",
        props: true,
        name: "AccountingImportsDCBL",
        component: () => import("../views/accounting/import/DCBL.vue"),
      },
      {
        title: "Accounting Remittance",
        path: "accounting/remittance",
        props: true,
        name: "AccountingRemittance",
        component: () =>
          import("../views/accounting/remittance/Remittance.vue"),
      },
      {
        title: "Accounting Payments",
        path: "accounting/payments",
        props: true,
        name: "AccountingPayments",
        component: () => import("../views/accounting/payments/Payments.vue"),
      },
      {
        title: "Roles",
        path: "roles",
        name: "Roles",
        component: () => import("../views/users/roles/Roles.vue"),
      },
      {
        title: "Role",
        path: "roles/:id",
        name: "Role",
        component: () => import("../views/users/roles/Role.vue"),
      },
      {
        title: "Permissions",
        path: "permissions",
        name: "Permissions",
        component: () => import("../views/users/permissions/Permissions.vue"),
      },
      {
        title: "Permission",
        path: "permissions/:id",
        name: "Permission",
        component: () => import("../views/users/permissions/Permission.vue"),
      },
      {
        title: "Permission Groups",
        path: "groups",
        name: "PermissionGroups",
        component: () => import("../views/users/groups/PermissionGroups.vue"),
      },
      {
        title: "Permission Group",
        path: "groups/:id",
        name: "PermissionGroup",
        component: () => import("../views/users/groups/PermissionGroup.vue"),
      },
      {
        title: "Monitoring",
        path: "monitoring",
        props: true,
        name: "Monitoring",
        component: () => import("../views/monitoring/Monitoring.vue"),
      },
      {
        title: "Camera Monitoring",
        path: "monitoring/cameras",
        props: true,
        name: "MonitoringCameras",
        component: () => import("../views/monitoring/pages/Cameras.vue"),
      },
      {
        title: "Tablet Monitoring",
        path: "monitoring/tablets",
        props: true,
        name: "MonitoringTablets",
        component: () => import("../views/monitoring/pages/Tablets.vue"),
      },
      {
        title: "Tablet Uptime",
        path: "monitoring/tablets/:id/uptime",
        props: true,
        name: "MonitoringTabletsUptime",
        component: () => import("../views/sites/site/SiteTabletUptime.vue"),
      },
      {
        title: "Mail Queue Monitoring",
        path: "monitoring/queue",
        props: true,
        name: "MonitoringMailQueue",
        component: () => import("../views/monitoring/pages/MailQueue.vue"),
      },
      {
        title: "Display Monitoring",
        path: "monitoring/displays",
        props: true,
        name: "MonitoringDisplays",
        component: () => import("../views/monitoring/pages/Displays.vue"),
      },
      {
        title: "Reports",
        path: "reports",
        props: true,
        name: "Reports",
        component: () => import("../views/reports/Reports.vue"),
      },
      {
        title: "In Out Report",
        path: "reports/inout",
        props: true,
        name: "SiteInOutReport",
        component: () => import("../views/reports/views/InOut.vue"),
      },
      {
        title: "Site Health Report",
        path: "reports/sitehealth",
        props: true,
        name: "SiteHealthReport",
        component: () => import("../views/reports/views/SiteHealth.vue"),
      },
      {
        title: "Open Claims Report",
        path: "reports/openclaims",
        props: true,
        name: "OpenClaimsReport",
        component: () => import("../views/reports/views/OpenClaims.vue"),
      },
      {
        title: "Face Incidents Page",
        path: "face/incidents",
        props: true,
        name: "FaceIncidentsPage",
        component: () => import("../views/face/FaceIncidentsPage.vue"),
      },
      {
        title: "Face Alerts Page",
        path: "face/alerts",
        props: true,
        name: "FaceAlertsPage",
        component: () => import("../views/face/FaceAlertsPage.vue"),
      },
      {
        title: "Face Tools Page",
        path: "face/tools",
        props: true,
        name: "FaceToolsPage",
        component: () => import("../views/face/FaceToolsPage.vue"),
      },
      {
        title: "Manage Face Incidents",
        path: "face/incidents/approved",
        props: true,
        name: "ManageFaceIncidents",
        component: () =>
          import("../views/face/incidents/standard/ApprovedIncidents.vue"),
      },
      {
        title: "Review Face Incidents",
        path: "face/incidents/pending",
        props: true,
        name: "ReviewFaceIncidents",
        component: () =>
          import("../views/face/incidents/standard/PendingIncidents.vue"),
      },
      {
        title: "Manage Awaiting Incidents",
        path: "face/incidents/awaiting",
        props: true,
        name: "AwaitingFaceIncidents",
        component: () =>
          import("../views/face/incidents/standard/AwaitingIncidents.vue"),
      },
      {
        title: "Review Rejected Face Incidents",
        path: "face/incidents/rejected",
        props: true,
        name: "RejectedFaceIncidents",
        component: () =>
          import("../views/face/incidents/standard/RejectedIncidents.vue"),
      },
      {
        title: "Approved National Incidents",
        path: "face/incidents/approvednational",
        props: true,
        name: "ApprovedNationalIncidents",
        component: () =>
          import(
            "../views/face/incidents/national/ApprovedNationalncidents.vue"
          ),
      },
      {
        title: "Pending National Incidents",
        path: "face/incidents/pendingnational",
        props: true,
        name: "PendingNationalIncidents",
        component: () =>
          import(
            "../views/face/incidents/national/PendingNationalIncidents.vue"
          ),
      },
      {
        title: "Rejected National Incidents",
        path: "face/incidents/rejectednational",
        props: true,
        name: "RejectedNationalIncidents",
        component: () =>
          import(
            "../views/face/incidents/national/RejectedNationalIncidents.vue"
          ),
      },
      {
        title: "Pending Closed Incidents",
        path: "face/incidents/pendingclosed",
        props: true,
        name: "PendingClosedIncidents",
        component: () =>
          import("../views/face/incidents/closed/pendingClosed.vue"),
      },
      {
        title: "Closed Incidents",
        path: "face/incidents/closed",
        props: true,
        name: "ClosedIncidents",
        component: () =>
          import("../views/face/incidents/closed/closedIncidents.vue"),
      },
      {
        title: "Incident Page",
        path: "face/incidents/:site_id/:id",
        props: true,
        name: "IncidentReview",
        component: () => import("../views/face/incidents/incident.vue"),
      },
      {
        title: "Alerts",
        path: "face/alerts/alertslist",
        props: true,
        name: "Alerts",
        component: () => import("../views/face/alerts/standard/alerts.vue"),
      },
      {
        title: "National Alerts",
        path: "face/alerts/national",
        props: true,
        name: "NationalAlerts",
        component: () =>
          import("../views/face/alerts/national/NationalAlerts.vue"),
      },
      {
        title: "Alert",
        path: "face/alerts/:site_id/:id",
        props: true,
        name: "Alert",
        component: () => import("../views/face/alerts/alert.vue"),
      },
      {
        title: "Display Alert Triggers",
        path: "face/alerts/displaytriggers",
        props: true,
        name: "DisplayAlertTriggers",
        component: () => import("../views/face/alerts/displaytriggers/displayAlertTriggers.vue"),
      },
      {
        title: "Incident Map",
        path: "face/map",
        props: true,
        name: "IncidentMap",
        component: () => import("../views/face/incidentmap.vue"),
      },
      {
        title: "Face Similarity",
        path: "face/tools/facesimilarity",
        props: true,
        name: "FaceSimilarity",
        component: () =>
          import("../views/face/incidents/faceSimilarityChecker.vue"),
      },
      {
        title: "AOS Census Report",
        path: "reports/aos",
        props: true,
        name: "AosCensusReport",
        component: () => import("../views/reports/views/Aos.vue"),
      },
      {
        title: "Parking Performance",
        path: "reports/parkingperformance",
        props: true,
        name: "ParkingPerformance",
        component: () =>
          import("../views/reports/views/ParkingPerformance.vue"),
      },
      {
        title: "Parking Accuracy",
        path: "reports/parkingaccuracy",
        props: true,
        name: "ParkingAccuracy",
        component: () => import("../views/reports/views/ParkingAccuracy.vue"),
      },
      {
        title: "Claims Created",
        path: "reports/claimscreated",
        props: true,
        name: "ClaimsCreated",
        component: () => import("../views/reports/views/ClaimsCreated.vue"),
      },
      {
        title: "No Claims Created",
        path: "reports/noclaims",
        props: true,
        name: "NoClaims",
        component: () => import("../views/reports/views/NoClaims.vue"),
      },
      {
        title: "Letters Sent",
        path: "reports/letterssent",
        props: true,
        name: "LettersSent",
        component: () => import("../views/reports/views/LettersSent.vue"),
      },
      {
        title: "National Blacklist",
        path: "blacklist",
        props: true,
        name: "NationalBlacklist",
        component: () => import("../views/blacklist/Blacklist.vue"),
      },
      {
        title: "Vehicles",
        path: "vehicles",
        props: true,
        name: "Vehicles",
        component: () => import("../views/vehicles/Vehicles.vue"),
      },
      {
        title: "Vehicle",
        path: "vehicles/:registration",
        props: true,
        name: "Vehicle",
        component: () => import("../views/vehicles/Vehicle.vue"),
      },
      {
        title: "Ordering",
        path: "ordering",
        props: true,
        name: "Ordering",
        component: () => import("../views/ordering/Ordering.vue"),
      },
      {
        title: "Ordering",
        path: "ordering/products",
        props: true,
        name: "Products",
        component: () => import("../views/ordering/pages/Products.vue"),
      },
      {
        title: "Ordering",
        path: "ordering/orders",
        props: true,
        name: "Orders",
        component: () => import("../views/ordering/pages/Orders.vue"),
      },
      {
        title: "Ordering",
        path: "ordering/emails",
        props: true,
        name: "Emails",
        component: () => import("../views/ordering/pages/Emails.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store.state.auth.status.loggedIn) {
    console.info("Not logged in and not loading login page, redirecting!");
    return next({ name: "Login" });
  }

  next();
});

export default router;
